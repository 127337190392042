import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>ProjectDS - Web & App Development</title>
                <meta name="description" content="Fast, transparent, efficient software development services in Toronto" />
                <meta name="og:title" property="og:title" content="ProjectDS - Web & App Development"></meta>
                <meta name="twitter:card" content="Project DS - Fast, transparent and efficient software development services in Toronto"></meta>
                <link rel="canonical" href="https://projectds.tech/"></link>
                <meta property="og:image" content="https://projectds.tech/logo.png" />
            </Helmet>
        </div>
    )
}

export default SEO
